import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';

const amplifyConfig = {
  Auth: {
    identityPoolId: 'us-west-2:fe0065c4-2cd6-443b-b2c6-af8442f99273',
    region: 'us-west-2'
  }
}
//Initialize Amplify
Auth.configure(amplifyConfig);

const analyticsConfig = {
  AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: 'c3abfa27034a40af8b84cc29a58638c4',
        // Amazon service region
        region: 'us-west-2',
        mandatorySignIn: false,
  }
}

Analytics.configure(analyticsConfig)

// Record an event
Analytics.record('visit-landingpage');

// Record a custom event
Analytics.record({
    name: 'landingpage',
    attributes: { 'stage':'coming-soon' }
});

class HomePage extends React.Component {
    render() {
        return (
            <div>
                <div className="container" style={{paddingTop: 3 + 'em'}}>
                    <h2>vietnam-produce.com coming soon!</h2>
                    <p>Thank you for checking out vietnam-produce.com. We are working to get the website up and running.</p>
                    <p>Please come back again.</p>
                </div>

                <div className="container" style={{paddingTop: 3 + 'em'}}>
                    <img src={ require('./img/vietnam_future.jpg' )} alt='Vietnam Future' />
                </div>

                <div className="container" style={{paddingTop: 3 + 'em'}}></div>
            </div>
        );
    }
}

// ========================================

ReactDOM.render(
    <HomePage />,
    document.getElementById('root')
);
